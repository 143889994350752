@import "../mixins/fonts.scss";

.card {
  .cardHeader {
    .imgWrap {
      width: 100%;
      height: 240px;
      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .cardContent {
    padding: 18px 12px;
    text-align: center;

    .subheader {
      @include setFontPrimarySemiBold(16px, var(--clr-secondary));
      padding-bottom: 15px;
    }

    .header {
      @include setFontPrimarySemiBold(18px, var(--clr-primary));
      padding-bottom: 15px;
    }
    .desc {
      @include setFontPrimary(16px, var(--clr-secondary));
    }
  }
}
.card:not(:last-child) {
  margin-bottom: 18px;
}
@media screen and (min-width: 991px) {
  .card:not(:last-child) {
    margin-bottom: 0px;
  }
  .card {
    width: calc(100% - 20px);
    margin: 0px 10px;
  }
}
